<template>
  <v-container>
    <v-row justify="center" class="mt-3">
      <v-col class="text-center">
        <h4 class="text-h4 font-weight-regular">Porichoy Request</h4>
      </v-col>
    </v-row>

    <v-row v-if="!loading" justify="center">
      <v-col cols="12" xl="8">
        <div class="grey--text text-center py-15" v-if="loading">
          <v-progress-circular indeterminate size="50" />
          <div class="caption my-2">Loading request...</div>
        </div>
        <v-expansion-panels :value="1" v-else>
          <v-expansion-panel v-if="porichoyRequestInfo">
            <v-expansion-panel-header class="text-h5 font-weight-regular lighten-4">
              Request Info
            </v-expansion-panel-header>
            <v-divider />
            <v-expansion-panel-content>
              <v-simple-table :loading="loading" class="text-right">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>Requested At</td>
                      <td class="text-left">
                        {{ formatDate(porichoyRequestInfo.createdAt) }}
                      </td>
                    </tr>
                    <tr v-if="porichoyRequestInfo.requestUserId && canShowUserInfo()">
                      <td>Requested By</td>
                      <td class="text-left">
                        <v-btn
                          outlined
                          :to="{
                            name: 'user.view',
                            params: { id: porichoyRequestInfo.requestUserId },
                          }"
                        >
                          {{
                            porichoyRequestInfo.requestUserName || porichoyRequestInfo.requestUserId
                          }}
                        </v-btn>
                      </td>
                    </tr>
                    <tr v-for="item in porichoyInfoItems" :key="item.name">
                      <td>{{ item.name }}</td>
                      <td class="text-left">
                        {{ emptyChecker(item.value(porichoyRequestInfo)) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="porichoyVoterInfo">
            <v-expansion-panel-header class="text-h5 font-weight-regular lighten-4">
              Voter Info
            </v-expansion-panel-header>
            <v-divider />
            <v-expansion-panel-content>
              <v-simple-table :loading="loading" class="text-right">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>Requested At</td>
                      <td class="text-left">
                        {{ formatDate(porichoyVoterInfo.createdAt) }}
                      </td>
                    </tr>
                    <tr v-if="porichoyVoterInfo.requestUserId && canShowUserInfo()">
                      <td>Requested By</td>
                      <td class="text-left">
                        <v-btn
                          outlined
                          :to="{
                            name: 'user.view',
                            params: { id: porichoyVoterInfo.requestUserId },
                          }"
                        >
                          {{ porichoyVoterInfo.requestUserName || porichoyVoterInfo.requestUserId }}
                        </v-btn>
                      </td>
                    </tr>
                    <tr v-for="item in voterInfoItems" :key="item.name">
                      <td>{{ item.name }}</td>
                      <td class="text-left">
                        {{ emptyChecker(item.value(porichoyVoterInfo)) }}
                      </td>
                    </tr>
                    <tr>
                      <td>Photo</td>
                      <td class="text-left">
                        <img
                          :src="`data:image/png;base64,${porichoyVoterInfo.photoBase64}`"
                          alt="Photo"
                          class="nid-image"
                        />
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { PORICHOY_REQUEST_INFO, PORICHOY_VOTER_INFO } from "@/constants/api";

export default {
  name: "PorichoyRequest",
  metaInfo: { title: "Porichoy Request" },
  components: {},
  data() {
    return {
      loading: false,
      porichoyVoterInfo: null,
      voterInfoItems: [
        { name: "Nid", value: (item) => item.nid },
        { name: "Name (Bengali)", value: (item) => item.nameBn },
        { name: "Name (English)", value: (item) => item.nameEn },
        { name: "Date Of Birth", value: (item) => item.dateOfBirth },
        { name: "Blood Group", value: (item) => item.bloodGroup },
        { name: "Father Name (Bengali)", value: (item) => item.fatherNameBn },
        { name: "Father Name (English)", value: (item) => item.fatherNameEn },
        { name: "Mother Name (Bengali)", value: (item) => item.motherNameBn },
        { name: "Mother Name (English)", value: (item) => item.motherNameEn },
        { name: "Occupation (Bengali)", value: (item) => item.occupationBn },
        { name: "Occupation (English)", value: (item) => item.occupationEn },
        { name: "PIN", value: (item) => item.pin },
        { name: "Spouse Name (Bengali)", value: (item) => item.spouseNameBn },
        { name: "Spouse Name (English)", value: (item) => item.spouseNameEn },
        { name: "Present Address (Bengali)", value: (item) => item.presentAddressBn.addressLine },
        { name: "Present Address (English)", value: (item) => item.presentAddressEn.addressLine },
        {
          name: "Permanent Address (Bengali)",
          value: (item) => item.permanentAddressBn.addressLine,
        },
        {
          name: "Permanent Address (English)",
          value: (item) => item.permanentAddressEn.addressLine,
        },
      ],
      porichoyRequestInfo: null,
      porichoyInfoItems: [
        { name: "Nid", value: (item) => item.nid },
        { name: "Date Of Birth", value: (item) => item.dob },
        { name: "Payable", value: (item) => (item.isPayable ? "YES" : "NO") },
        { name: "Cache Used", value: (item) => (item.useCache ? "YES" : "NO") },
        { name: "With English", value: (item) => (item.withEnglish ? "YES" : "NO") },
      ],
    };
  },
  mounted() {
    if (this.$route.params.voterInfoId) {
      this.getNidScraperVoterInfoRequest(this.$route.params.voterInfoId);
    }
    if (this.$route.params.requestId && this.$route.params.requestId !== "_") {
      this.getPorichoyRequestInfoRequest(this.$route.params.requestId);
    }
  },
  methods: {
    emptyChecker(val, placeholder = "-") {
      return val ? val : placeholder;
    },
    getNidScraperVoterInfoRequest(voterInfoId) {
      this.loading = true;
      return this.$axios
        .get(`${PORICHOY_VOTER_INFO}?id=${voterInfoId}`)
        .then((response) => {
          if (response.data.code === 0) {
            this.porichoyVoterInfo = response.data.data;
            return;
          }
          throw new Error(response.data.message);
        })
        .catch((err) => {
          this.$iziToast.showError(err);
          this.$router.back();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPorichoyRequestInfoRequest(requestId) {
      this.loading = true;
      return this.$axios
        .get(`${PORICHOY_REQUEST_INFO}?id=${requestId}`)
        .then((response) => {
          if (response.data.code === 0) {
            this.porichoyRequestInfo = response.data.data;
            return;
          }
          throw new Error(response.data.message);
        })
        .catch((err) => {
          this.$iziToast.showError(err);
          this.$router.back();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.nid-image {
  max-width: 150px;
  padding: 10px;
}
.dump-panel {
  height: 60vh;
  iframe {
    padding: 0.5rem;
    border: none;
    height: 60vh;
  }
}
</style>
